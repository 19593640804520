<template>
  <div class="footer d-flex align-items-center justify-content-center">
    <footer>
      <div class="container row mx-auto" id="footer">
        <div class="column col-12">
          <div class="titulo d-flex justify-content-center justify-content-md-start">
            <div class="d-flex align-items-start flex-wrap mb-4">
              <img class="logo-sbp" src="@/assets/img/logo-header.png" alt="" />
              <img class="logo-juntos" src="@/assets/img/logo-juntos-contra-o-mosquito.png" alt="" />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <ul class="nav">
                <li class="nav-item col-12">
                  <router-link class="nav-link" active-class="active" :to="{ name: 'Home' }" exact>Home</router-link>
                </li>
                <li class="nav-item col-12">
                  <a class="nav-link" href="https://www.sbpprotege.com.br/quem-somos"  target="_blank">Quem somos</a>
                </li>
                <li class="nav-item col-12">
                  <router-link class="nav-link" active-class="active" :to="{ name: 'Home' }" exact>Juntos contra o
                    mosquito</router-link>
                </li>
                <li class="nav-item col-12">
                  <a class="nav-link" href="https://www.sbpprotege.com.br/produtos" target="_blank">Produtos</a>
                </li>
                <li class="nav-item col-12">
                  <a class="nav-link" href="https://www.sbpprotege.com.br/proteja-se" target="_blank">Proteja-se</a>
                </li>
                <li class="nav-item col-12">
                  <a class="nav-link" href="https://www.amazon.com.br/stores/page/86427D33-9D11-4E07-A276-C1F4FE8B3FCB?channel=website" target="_blank">Compre agora</a>
                </li>
                <li class="nav-item col-12">
                  <a class="nav-link" href="https://www.sbpprotege.com.br/duvidas-fale-conosco" target="_blank">Dúvidas &
                    Fale conosco</a>
                </li>
                <li class="nav-item col-12">
                  <a class="nav-link" href="https://www.sbpprotege.com.br/insetopedia" target="_blank">Insetopédia</a>
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-6">
              <div class="row justify-content-between" style="height: 100%;">
                <div class="col-12">
                  <ul class="nav">
                    <li class="nav-item col-12">
                      <a class="nav-link" href="https://www.sbpprotege.com.br/policies/politica-de-privacidade"
                        target="_blank">Política de Privacidade</a>
                    </li>
                    <li class="nav-item col-12">
                      <a class="nav-link" href="https://www.sbpprotege.com.br/policies/politica-de-cookies"
                        target="_blank">Política de Cookies</a>
                    </li>
                    <li class="nav-item col-12">
                      <a class="nav-link" href="https://www.sbpprotege.com.br/policies/termos-e-condicoes"
                        target="_blank">Termos e Condições</a>
                    </li>
                    <li class="nav-item col-12">
                      <a class="nav-link"
                        href="https://www.sbpprotege.com.br/policies/aviso-de-privacidade-de-relacoes-com-o-consumidor"
                        target="_blank">Aviso de Privacidade do Serviço de Atendimento ao
                        Cliente (SAC)</a>
                    </li>
                  </ul>
                </div>
                <div class="col-12 div-redes">
                  <ul class="nav d-flex justify-content-center justify-content-md-start redes-sociais">
                    <li class="nav-item">
                      <a class="nav-link" href="https://www.facebook.com/SBPBrasil" target="_blank">
                        <img src="@/assets/img/facebook-icon.png" alt="" />
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="https://www.instagram.com/sbpprotege/" target="_blank">
                        <img src="@/assets/img/instagram-icon.png" alt="" />
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="https://www.youtube.com/user/sbpbrasil" target="_blank">
                        <img src="@/assets/img/youtube-icon.png" alt="" />
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="https://twitter.com/SBPProtege" target="_blank">
                        <img src="@/assets/img/twitter-icon.png" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- <div class="col-12 col-md-6 offset-md-6">
              <ul class="nav d-flex justify-content-center justify-content-md-start redes-sociais">
                <li class="nav-item">
                  <a class="nav-link" href="https://www.facebook.com/SBPBrasil" target="_blank">
                    <img src="@/assets/img/facebook-icon.png" alt="" />
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="https://www.instagram.com/sbpprotege/" target="_blank">
                    <img src="@/assets/img/instagram-icon.png" alt="" />
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="https://www.youtube.com/user/sbpbrasil" target="_blank">
                    <img src="@/assets/img/youtube-icon.png" alt="" />
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="https://twitter.com/SBPProtege" target="_blank">
                    <img src="@/assets/img/twitter-icon.png" alt="" />
                  </a>
                </li>
              </ul>
            </div> -->
          </div>
        </div>

        <div class="col-12 col-md-6 mt-0 mt-md-5">
          <p class="copyright">
            © 2023 Reckitt Benckiser - Todos os direitos reservados
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {}
  },
  methods: {},
  created() { },
  mounted() { }
}
</script>

<style lang="scss" scoped>
.footer {
  background-color: $laranja;
  border: 0px solid transparent;
  width: 100wh;
  padding: 20px 0 40px;
  position: relative;
  z-index: 999;

  .titulo {
    .logo-juntos {
      width: 124px;
      object-fit: contain;
    }

    .logo-sbp {
      width: 100px;
      object-fit: contain;
      margin-right: 1rem;
    }
  }

  .nav-item {
    position: relative;

    a {
      color: $branco;
      font-family: $font1;
      font-weight: bold;
      font-style: normal;
      font-size: 18px;
      line-height: 20px;
      padding: 9px 10px;
      display: inline-block;
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 15px;
    }
  }

  .nav-item:last-child {
    a {
      &::before {
        content: '';
      }
    }
  }

  .redes-sociais {
    //padding-left: 1rem;
  }

  .div-redes {
    display: flex;
    align-items: end;
  }

  .copyright {
    color: $branco;
    font-family: $font1;
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 767.98px) {
  .footer {
    .nav-item {
      width: 100%;
      text-align: center;

      a {
        &::before {
          content: '';
        }
      }
    }

    p {
      text-align: justify;
      margin-top: 30px;
      padding: 0 0 0 0px;
    }

    .div-redes {
      display: block !important;
    }
  }

  .redes-sociais {
    .nav-item {
      width: auto;
    }
  }
}

@media (min-width: 768px) {
  .offset-md-6 {
    margin-left: 48.5% !important;
  }
}
</style>
