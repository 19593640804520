import Vue from 'vue'
import VueRouter from 'vue-router'
import Menu from '../layout/Header.vue'
import Footer from '../layout/Footer.vue'
import store from '@/store'

Vue.use(VueRouter)

const DEFAULT_TITLE = 'SBP Juntos Contra o Mosquito'

const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      default: () => import('../views/Home.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Home',
      requireLogin: false,
    }
  },
  {
    path: '/quem-somos',
    name: 'QuemSomos',
    components: {
      default: () => import('../views/QuemSomos.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Quem Somos',
      requireLogin: false,
    }
  },
  {
    path: '/expedicao-planeta',
    name: 'ExpedicaoPlaneta',
    components: {
      default: () => import('../views/ExpedicaoPlaneta.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Expedição Planeta',
      requireLogin: false,
    }
  },
  {
    path: '/edicao-2022-2023',
    name: 'Edicao2022',
    components: {
      default: () => import('../views/Edicao2022.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Edição 2022/2023',
      requireLogin: false,
    }
  },
  {
    path: '/edicao-2023-2024',
    name: 'Edicao2023',
    components: {
      default: () => import('../views/Edicao2023.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Edição 2023/2024',
      requireLogin: false,
    }
  },
  {
    path: '/aviso-privacidade',
    name: 'AvisoPrivacidade',
    components: {
      default: () => import('../views/AvisoPrivacidade.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Aviso Privacidade',
      requireLogin: false,
    }
  },
  {
    path: '/politica-cookies',
    name: 'PoliticaCookies',
    components: {
      default: () => import('../views/PoliticaCookies.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Política Cookies',
      requireLogin: false,
    }
  },
  {
    path: '/politica-privacidade',
    name: 'PoliticaPrivacidade',
    components: {
      default: () => import('../views/PoliticaPrivacidade.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Política Privacidade',
      requireLogin: false,
    }
  },
  {
    path: '/termos-uso',
    name: 'TermosUso',
    components: {
      default: () => import('../views/TermosUso.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Termos de Uso',
      requireLogin: false,
    }
  },
  {
    path: '*',
    name: 'NotFound',
    components: {
      default: () => import('../views/NotFound.vue'),
      menu: Menu,
      footer: Footer
    },
    meta: {
      title: 'Página Não Encontrada',
      requireLogin: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title + ' | ' + DEFAULT_TITLE || DEFAULT_TITLE;
  })
})



router.beforeEach((to, from, next) => {

  store.commit('rotaAtual', to.name)
  if (to.meta.requireLogin) {
 
      next()
  
  } else {
  
    next()
  }
  if (to.meta.requireCadastro) {
   
   
    next()
    
  }
  if (to.meta.requirePeriodo) {
    next()
  }
})



export default router
