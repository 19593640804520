<template>
  <nav class="navbar navbar-expand-md menu">
    <div class="container-fluid">
      <div class="navbar-brand"></div>
      <button
        class="navbar-toggler hamburger hamburger--elastic-r"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#menuCenter"
        aria-controls="menuCenter"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <div class="hamburger-box">
          <div class="hamburger-inner"></div>
        </div>
      </button>
      <div
        class="collapse navbar-collapse justify-content-md-center"
        id="menuCenter"
      >
        <ul class="navbar-nav">
          <!-- <li class="nav-item">
                      <router-link class="nav-link" active-class="active" :to="{ name: 'Home' }" exact>Home
                      </router-link>
                  </li> -->
          <li class="nav-item">
            <router-link
              :to="{ path: '/', hash: 'o-movimento' }"
              class="nav-link"
            >
              O movimento
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ path: '/', hash: 'como-atuamos' }"
              class="nav-link"
            >
              Como atuamos
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ path: '/', hash: 'resultados' }"
              class="nav-link"
            >
              Resultados
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ path: '/', hash: 'casa-protegida' }"
              class="nav-link"
            >
              Casa protegida
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['isLogin'])
  },

  mounted() {
    var forEach = function (t, o, r) {
      if ('[object Object]' === Object.prototype.toString.call(t))
        for (var c in t)
          Object.prototype.hasOwnProperty.call(t, c) && o.call(r, t[c], c, t)
      else for (var e = 0, l = t.length; l > e; e++) o.call(r, t[e], e, t)
    }

    var hamburgers = document.querySelectorAll('.hamburger')
    if (hamburgers.length > 0) {
      forEach(hamburgers, function (hamburger) {
        hamburger.addEventListener(
          'click',
          function () {
            this.classList.toggle('is-active')
          },
          false
        )
      })
    }
    var largura =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    if (largura <= 767) {
      var descendentes = document.querySelectorAll('#menuCenter a')
      for (var i = 0; i < descendentes.length; i++) {
        descendentes[i].addEventListener('click', function () {
          document.getElementById('btn-hamburger').click()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  top: 0;
  width: 100%;
  padding: 10px 0;
  background-color: $laranja-claro;

  .navbar-nav {
    li {
      a {
        color: $preto;
        font-family: $font3;
        font-weight: 600;
        font-style: normal;
        font-size: 20px;
        line-height: 20px;
        padding: 10px 36px;

        &:hover,
        &:focus {
          color: $preto;
        }
      }
    }

    li a.active {
      color: $preto;
    }
  }
}

@media (max-width: 767.98px) {
  .menu {
    // position: fixed;
    // top: 0;
    // width: 100%;
    // z-index: 100;
    // padding: 0;

    .navbar-nav li a {
      font-size: 16px;
      line-height: 20px;
      padding: 12px 48px;
    }

    .btn-login {
      color: $preto;
      background-color: $branco;
      border-color: $branco;

      &:hover,
      &:focus {
        color: $preto;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .menu .navbar-nav li a {
    font-size: 16px;
    line-height: 20px;
    padding: 10px 27px;
  }
}
</style>
