import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    paginaAtual: '',
    loading: false,
  },

  mutations: {
    setLoading(state, value) {
      state.loading = value
    },

    rotaAtual(state, value) {
      state.paginaAtual = value
    },

  },

  getters: {
    isLoading(state) {
      return state.loading
    },

  },

  actions: {

  },

})